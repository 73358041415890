# try loading on hover now
# on dragstart make sure all textareas on the screen are enabled?
# '.autodrop'.live 'mouseover focus touchend', ->
# ugh, htf to write this properly
# document.addEventListener('dragstart', (e) -> qa('textarea').forEach((v) => setup_drag(v)))

# lets give a brief stab at doing this without dragdrop

# if it's not already there, add a hidden file input & click it
'.convo_image_button'.live (e) ->
  v = e.target
  if !v.nextSibling || v.nextSibling.nodeName != 'INPUT'
    # also append a preview element we'll add to onChange. maybe create element programmatically https://stackoverflow.com/questions/5656392/how-to-create-input-type-text-dynamically
    v.parentNode.insertAdjacentHTML('afterend', "<div class='my_preview'></div>")
    v.insertAdjacentHTML('afterend', "<input name='upload' onChange='convo_image_preview(event)' style='display:none' type='file' accept='image/png, image/gif, image/jpeg' />")

  v.nextSibling.click()

# lets only allow one image at a time on purpose for now https://stackoverflow.com/questions/4459379/preview-an-image-before-it-is-uploaded
window.convo_image_preview = (e) =>
  console.log('preview with', e.target)
  e.target.parentNode.parentNode.querySelector('.my_preview').innerHTML = "<img src='#{URL.createObjectURL(e.target.files[0])}' />"

# disabling drag now
#how to better trigger this? add a show for the icon, trigger load on click
# document.addEventListener 'dragenter', (e) -> 
#   return if !u or u.plan == 'free'
#   for v in queryAll('textarea')
#     setup_drag v

# '.convo_image_button_dis'.live (e) ->
#   # find textarea next to this button
#   window.v = v = e.target.parentNode.querySelector('textarea')
#   setup_drag(v)
#   v.dropzone.hiddenFileInput.click() # how to target the dropzone from the id?

# # drag, dragdrop. If we're using the icon not sure if we need this, though the automatic preview + display is nice
# # maybe downgrade to the other dropzone, or roll my own since this shit is crazy bad
# setup_drag = (v) => 
#   return true if v.classList.contains('dropped') or user.plan != 'gold'
#   v.classList.add('dropped')
#   console.log('setup drag on', v)
#   getSync "https://unpkg.com/dropzone@6.0.0-beta.1/dist/dropzone-min.js" if !window.Dropzone
#   rng = Math.floor Math.random()*9999
#   v.id ||= "dz#{rng}"
#   v.insertAdjacentHTML('afterend', "<span id='dz_prev_#{rng}'></span>")
#   # ideally figure out textarea location & add a picture icon somewhere to click? See if drop-only works
#   new Dropzone "##{v.id}", previewsContainer: "#dz_prev_#{rng}", url: "/image/add/dz",  clickable: false, success: (file, response) -> 
#     console.log("success", file, response)
#     file.previewElement.classList.add("dz-success")
#     response = "<img src='#{response}' />" #if !file.previewElement.closest('.convo_container')
#     qt = q('textarea', file.previewElement.closest('form'))
#     qt.value += "\n"+response # sometimes img vs link
#     qt.style.height = "#{qt.scrollHeight + 2}px" # qt.dispatchEvent(new KeyboardEvent('keyup')); # fails
#     cc?.fix_msgbox_height('force') #fix convos, wish we could keep this local
#   , queuecomplete: () -> cc?.fix_msgbox_height('force')

'.select_below'.live ->
  window.getSelection().removeAllRanges()
  window.range = document.createRange()
  window.w = $(@).next()
  range.selectNode(w.get(0))
  window.getSelection().addRange(range)

'.poem_clipboard'.live ->
  # create range to select text
  window.range = document.createRange()
  window.w = $('.fonted').first()
  range.selectNode w.get(0)
  window.getSelection().addRange range
  
  try
    res = document.execCommand('copy')
    console.log "Copy email command result: #{res}"
    
  catch err
    console.log 'Oops, unable to copy', err
  window.getSelection().removeAllRanges()
  $(@).parent().append("<br>Copied!")

'.bio img, .subitem_pic, .extra_image'.live ->

  # convert bio images to larger ones
  if @.src.match /_72/
    @.src = @.src.replace(/_72/,'_300')
  else if @.width == 72 # bio, add 'big'
    @.src = @.src.replace(/\.jpg/, "big.jpg") if !@.src.match(/big\./)
  else 
    @.src = @.src.replace(/-thumb/,'')
  
  @.classList.toggle('big')
  

# also want to support 'prefers-colors-scheme' like we do in VUAP
'.dark_mode_toggle'.live (e) ->   
  if document.body.classList.contains('custom') # switches dark vs. light bgs, awkward.
    # alert("This page uses a custom background, so dark mode has no effect.")
    # should it still toggle dark mode on? or act as a way to hide background?
    document.body.setAttribute('style','')
    document.body.classList.remove('custom')
  else
    dark_mode_toggle()

# toggling in/out of beta for things is always such a goddamn PITA & I spend a lot of time with force_new, default_old & the complex situations that arise from that.  Is there a simpler way?
# 2 days at a time
# why does this work in dev, but not in production! WTFFFFFF  wow it's compiling it completely wrong, super strange
'.old_msgs'.live ->
  console.log('old_msgs')
  $.cookie('ol_m', 1, 2)
  $(@).append("<i class='fa fa-check'></i>")
  # alert("Ok, using old messages")
  window.location = '/messages'
  # if window.location.pathname == '/convo'
  # else window.location.reload()

'.old_msgs_off'.live ->
  console.log('old_msgs_off')
  $.cookie('ol_m', 0, 2)
  $(@).append("<i class='fa fa-check'></i>")
  # alert("Ok, using new messages")
  # window.location.reload()

window.dark_mode_toggle = () ->
  cl = document.body.classList
  return if cl.contains('landing2') # /join

  if $.cookie('dk') == '1'
    $.cookie('dk', -1, 999)
    cl.remove('dark_mode','light_text')
    cl.add('dark_text')
  else
    $.cookie('dk', 1, 999)
    cl.add('dark_mode','light_text')
    cl.remove('dark_text')

# maybe remove .custom requirement?
# TODO: black paper? or invert it

# toggle whether links are shown or just 'read all'? Thought we did both always
'.list_link'.live ->
  n = 'li'
  cur = $('.list_links').is(':visible')
  console.log "cookie was", n, cur
  $.cookie(n, (if cur then 1 else null), 999)
  $('.list_links').toggle()

# translation tabs
'.tr_this'.live ->
  f = $(@).parents('.form')
  f.find('.read_more_link').click() if f.hasClass('closed')
  true

# hide new message notifications on mobile when we have input/textarea focused
'input,textarea'.live 'focus', ->
  if $('body').width() < 767 
    $('#msg-preview').addClass 'hidden'
  true

'input,textarea'.live 'focusout', ->
  $('#msg-preview').removeClass 'hidden'
  true

'.update_ar'.live ->
  $.post $(@).attr('href'),
      body: $('#item_rendered').val()
    , (data) ->
      $('.autorank_area').html(data)
  false

# RIPE for turning into a hotwire controller
# any inline js load, data-target.  only for tabs right now evidently, won't reload, ugh
'.load_to'.live ->
  return if $(@).hasClass 'already'
  tar = $( $(@).attr 'rel' ).last().html("Loading...") # wait_gif) #{}
  $(@).toggleClass 'active'

  # trouble with getting the css3 transitions to work easily repeatably.
  if tar.height() > 52 and $(@).hasClass 'already' #.length > 1 # toggle
    console.log "toggling load_to back off"
    $(@).toggleClass('.hidden')
    return false

  $.get $(@).attr('href'), (d) ->
    # console.log "load_to from, got", @, d
    v = $(tar).html(d) # replaceWith loses tar, container is better
    after_ajax(tar)
    # console.log('scrolling after load_to', v)
    # v.scrollTop v.prop('scrollHeight')-100 # scroll to bottom if necessary (group/message history)
    # $('html, body').animate({ scrollTop: v })

    # scroll to section on page if loading comments on contest/list
    $('.comment_cache').html "&larr; Full comments on left" # awkward...() # for item/show inline cmts hack
    # scroll_to(tar) #window.
    $(@).addClass 'already' # greys it out and now I can't click it, ugh.
    $(".u_#{user.id}").show() # new 'ou'
  false

# window.strong_hide = ->
#   $.cookie('strong_hide', 1, 999)
#   $('.tips').html("<div class='txt--right my1'><a href='#' class='update_rank'>tips</a></div>")

'.strong_hide'.live -> 
  $.cookie('strong_hide', 1, 999)
  $('.tips').html("<div class='txt--right my1'><a href='#' class='update_rank'>Show tips</a></div>")

# have this also stop draft.  Also give it a 5s delay from loading the screen
window.last_val ||= ''
'.update_rank'.live -> 
  v = $('#item_form textarea').val()
  return if window.last_val == v
  console.log('update_rank', window.last_val, v, window.last_val == v)
  window.last_val = v
  $.cookie('strong_hide', null, 999) if $.cookie('strong_hide')
  $.post "/item/metrics3", title: $('#item_title').val(), body: $('#item_form textarea').val(), (d) ->
    $('.tips').html d

'.online_favs_list a'.live (e) ->
  return redirect_to("/messages") if $(e.target).attr('href') == '/messages' # fix for safari bug?
  return true if $(e.target).is('span, .btn, .messages_link, .history_link') # make arrow link work? TODO: TEST
  return false if $(@).parents('li').next('form').length or $(@).is('.u') # already here,

  msg_window $(@), $(@).text().replace(/→|&nbsp;|^\s+| /g,'')
  window.scrollTo(0,0) # fix odd scroll bug
  $(@).parents('.online_favs_list').show() # reopen dropdown menu
  false

# ditch all this stuff once new msgs are up, trigger msgController to open R menu
'.message_this_author'.live ->
  msg_window $(@), $('#i').data('user')
  false

'.messages_link'.live (e) ->
  return if e.metaKey or e.altKey or e.ctrlKey or e.shiftKey or $('body').hasClass('messages') #or $('body').width() < 767
  return false if document.location.pathname.match(/^\/convo/) #alert("Already open")
  msg_open() #if $('#msg-window').is('.opened')
  false

  # $.get "/messages/index", (d) ->
  #   $('#just_more').html d
  #   after_ajax("#msg-window")
  #   $('#msg-window').addClass 'opened'
  
  # #why the delay vs instant? needs two clicks otherwise
  # delay 1, msg_open
  # msg_open
  # false

# want to be able to load the page w/o opening it (to make it faster @ drag for example)
window.msg_open = ->
  $('#msg-preview, .dropdown-menu').hide()
  $('.close_window').show() # wtf is this?
  $('#msg-window').css('right',0)  
  document.body.classList.add 'msgopen'
  msg_open_actual()

window.msg_open_actual = ->
  return if window.msg_loading or $('#msg-window').is('.opened')
  window.msg_loading=1
  $.get "/convo", (d) ->
    console.log("getting initial /convo")
    $('#just_more').html d
    after_ajax("#msg-window")
    $('#msg-window').addClass 'opened'
    q('.convo_list_mode').scrollTop=0 if $.cookie('l_m')

window.msg_close = ->
  # $("#overlay, #modal_join").hide()
  # console.log('closing messages')
  document.body.classList.remove 'msgopen'
  # $('#msg-window').css('margin-top',0)
  $('#msg-window').css('right','-9999px') # removed hiding of #msg-window, 
  # set_unread_num(0) if !$('.convo_container').length #if $(@).is('.close_window')
  # console.log("msg_close")
  false

'.close_window, #overlay'.live -> #touchstart
  window.msg_close()
  return false

'.group_top.closed'.live (e) ->
  return if $(e.target).is('.group_toggle')
  $(@).find('.group_toggle').click()

# MESSAGES - moving to default = closed.  supposedly 999 days but seems to fail a lot...
'.group_toggle'.live ->
  n = "o_#{$(@).attr('rel')}"
  cur = parseInt( $.cookie(n) ) || 0
  console.log "cookie", n, cur
  $.cookie(n, (if cur > 0 then null else 1), 999)
  console.log "current history for #{n} is #{cur}, now is: #{$.cookie(n)}"

  $(@).parents('.msg').toggleClass 'closed' #$(".#{n}").toggle()
  $(@).html if cur == 1 then '→' else '↓'

  # why is it navigating if I do this??? but not if I don't??? UGH!
  # msg_key is the top messages timestamp, what we use to cache page 1 of messages
  # if this isn't working, check that the cache key for messages#index didn't change & update in group_controller
  # $.post "/group/group_history_toggled/", {id: $('.msg_key').data('key')}
  # did I stop caching the first page of messages? think so
  console.log "past it"
  false

# not needed - can access cookies from rails instead
# window.group_default_toggle = ->
  # $('.group_toggle').each ->
    # $(@).click() if $.cookie("g_#{ $(@).attr 'rel'}") == "1" and !$(@).hasClass('closed')

# '.group_toggle'.live ->
#   n = "g_#{$(@).attr('rel')}"
#   cur = parseInt( $.cookie(n) ) || 0
#   console.log "cur: #{cur} for #{n}, #{cur > 0}"
#   $.cookie(n, (if cur > 0 then 1 else null), 999) # somehow this was backwards?  hmm..
#   $(@).parents('.msg').toggleClass 'closed' #$(".#{n}").toggle()
#   $(@).html if cur > 0 then '↓' else '→'
#
#   false

# having it click the correct convo will be easier.  Check if loaded @ click immediately else set somewhere to be run @ load
'#msg-preview'.live (e) ->
  # prompt to go to the 'just now: added a poem' page if it is one?  aah or go to newest by favorites, that is good.
  return if $(e.target).is('.x')
  
  if $(@).text().match(/just added/)
    if confirm("Do you want to visit your new by favorites page?")
      redirect_to "/items/following"
      return false

  # return redirect_to("/messages") unless $(window).width() >= 767 # using persistent window always now
  # $('body').scrollTop(0)
  # $('.messages_link:first').click() # open messages
  msg_open() # or return a promise? Hmm.
  # {this.data 'u'}
  # console.log(@, e.target, $(@), @.dataset, e.target.dataset)
  # console.log(@.dataset.u)
  # console.log("looking for .contact h3:contains(#{@.dataset.u})")
  if $('#msg-window').is('.opened')
    $(".contact h3:contains(#{@.dataset.u})").click() # no err if missing?
  else
    delay 700, =>
      $(".contact h3:contains(#{@.dataset.u})").click() # no err if missing?
  
  false

# what do we use just2 for?
'#msg-preview .x'.live ->
  $('#msg-preview').hide()
  # $.cookie 'just2', time_to_i()
  false

# don't need this unless turbo has problems?
# '.online_favs_list a span'.live 'click onTouchStart', (e) ->
#   $(e).preventDefault()
#   console.log "clicked a span"
#   $('.dropdown-menu').hide()
#   redirect_to $(@).parent('a').attr 'href'
#   false
#
# LIKE BUTTON

# FORMS
window.lastform = undefined
'form'.live "submit", ->
  window.recent_form = $(@)
  console.log "form submit"
  $(@).removeClass('dirty').trigger 'reinitialize.areYouSure'

  # console.log "submitting form"
  lastform = $(this)
  # $(@).data 'old-val', $(@).find('input[type=submit]').val()
  delay 6000, ->
    lastform.removeAttr('disabled')

# classic links + show_mob redirection for contests
# aah we're unbinding the first one, 
# make relative links
# something strange w/ google serach results!!!
site_links_relative = (a) ->
  hr = $(a).attr('href')
  return true if !hr or hr == '#'
  res = hr.replace /^http(s?):\/\/(allpoetry\.com|allpoetry-classic\.com|storywrite\.com)/, '' #(match, $1) -> ""
  $(a).attr 'href', res if hr != res
  console.log("Replaced_new to ", res) if hr != res
  true

'form.fix_token'.live 'submit', ->
  console.log "Fixing token"
  token = $( 'meta[name="csrf-token"]' ).attr( 'content' )
  $('.fix_token input[name=authenticity_token]').val token

# is this needed, or can I get rid of it?
# this is breaking all turbo-frames interaction I think?  No, it worked on the fucking tickets page?
# this is needed for method='post' to work
'a'.live (e) ->
  # return if @.classList.contains 'skip'
  site_links_relative(@)

  r = window.recent_form = $(@)
  return if r.hasClass 'x'
  # default for 'x'.  somehow it can have *TWO* data-confirms wtf
  # r.data('method', 'post').data('confirm', "Are you sure you want to remove this?").data('remote', 'true') if r.hasClass('x') and !r.data('confirm').length

  # data-confirm - only supported for links, not for forms
  # method = 'post' (not using rails tools)  how to redirect us to the result?  Hm.
  # TODO: move data-confirm and data-method to data-turbo-confirm/method
  if r.data('method') == 'post'
    token = $( 'meta[name="csrf-token"]' ).attr( 'content' )
    form = $("<form method='post' action=\"#{r.attr 'href'}\"><input type='hidden' name='authenticity_token' value='#{token}' /></form>").hide()
    console.log "data method post", form

    if r.data('confirm')
      console.log 'confirm', @
      if confirm( r.data('confirm') )
        form.hide().appendTo('body').submit()
        r.parents('.itm').html('Deleted') if r.hasClass('x') # slideup broken?

    else
      form.hide().appendTo('body').submit()

    return false

  else if r.hasClass 'disabled'
    console.log "doing nothing since disabled"
    window.autoen = delay 2800, => r.removeClass("disabled")
    return false

  else if r.hasClass('btn') and r.is('.remote,.load_to,.toggle')# using .already for 'active' toggle for some reason?
    # hide if it if was already on
    if r.hasClass 'already'
      $(r.attr 'rel').toggle()
      return false

    r.addClass('already')

  else if r.attr('href') == '#'
    # console.log "href is #"
    return false

  else if r.hasClass('btn')
    # console.log "my disable", r.attr('href')
    r.addClass('disabled')
    delay 3000, => r.removeClass('disabled')
  true

# doing a custom one for .x
'a.x'.live (e) ->
  return if $(@).parents('#msg-preview').length
  window.recent_form = $(@).parents('.comments')

  $(@).removeClass 'remote' # sometimes had both, cauess probelms
  e.preventDefault() # don't bubble to .itm
  console.log 'x', $(@)
  msg = $(@).data('confirm') || "Are you sure?"

  if confirm(msg)
    # .media = comments
    par = $(@).parents('.o,.itm,.obj,.msg,.media').first().html("...").css('background-image','')
    $('.popover').hide()
    # console.log "par", par

    # not calling this with errors though, hmm...
    $.post $(@).attr('href'), (data) ->
      # console.log ".x res", data
      par.html( data ).css('min-height', 0)

    # $(@).parent('.itm,.obj').addClass('fadeOut') # .html("Deleted") - or replace with response?
  false


# SEARCH
"form.search".live 'submit', ->
  return if $('body').hasClass('classics')
  # console.log "form.search submit"
  $(@).find(".results").show().html("Loading...")

  $.get $(@).attr('action'), $(@).serialize(), (d) =>
    # console.log "search data", d
    hr = "<div class='hr' style='margin: 30px 0'></div>"
    res = $(@).find(".results")
    res = $('.results:first') if !res.length
    # console.log "res is", res
    # addClass('slideDown'). - flickers strangely
    res.html(d + hr).show()

    # what is thi used for? $(@).find('a').data('method', 'post') if $(@).find('input[name=url]').length # only do this if form action includes a url?
    fix_noguest()
    after_ajax res #('.results')
    $('input[type=submit]').val "Submit"
    # if there's only one result, "click" it?  Hmm, only want this for some things that use ajax.  can we trigger click without following url?
    #result_divs = $('.results .items > div')

    # book/shared click result if only one
    if $('body').hasClass('bookshared')
      console.log('shared clicking top result')
      itms = res.find('.itm')
      if (itms.length == 1)
        $(".itm:first").click()

  false

'.search-recent'.live ->
  $(@).parents('form').find('input[type=text], input[type=search]').val('').end().submit()
  false

# MISC
# get dragdrop api
'.image_button'.live ->
  console.log 'image btn'

'.st_shares > a'.live (event) ->
  console.log "st_shares click"
  return true if $(@).hasClass 'no_pop' or $(window).width() < 767 # fallback
  console.log("opening fb pop")
  nwin = window.open($(this).attr("href"), "shareWindow", "width=600,height=300,menubar=no,toolbar=no,left=300,top=300")
  nwin.focus() if window.focus
  false

# mobile send message @ click of name
'.msg_user'.live ->
  msg_window $(@), $(@).text()
  window.scrollTo(0,0) # fix odd scroll bug
  false


# want to support the user including or NOT include a '.'
'.toggle'.live ->
  redirect_to "/login" if user.name == 'Guest' and $(@).attr('rel') == 'mob_menu'
  console.log "toggle", $(@).attr('rel')
  rel = $(@).attr 'rel'
  rel = ".#{rel}" unless rel.match /\.|#/

  # whats selected for?
  $(@).parent().find('.active').removeClass 'active'
  $(@).toggleClass('active')

  $(  $(@).data('off') ).hide()
  # this doesn't work with non-block elements
  $(rel).toggle()

  # console.log $(rel).css('display')
  # if $(rel).hasClass('media') and $(rel).css('display') == 'block'
  #   $(rel).css 'display', 'flex'

  if $(@).data('cookie') # used in group_new
    ex = parseInt $.cookie $(@).data('cookie')
    console.log "current: #{ex}, setting to: #{!ex}"
    v = if ex == 1 then 0 else 1
    $.cookie $(@).data('cookie'), v

  # allow label togglign for any type?
  if $(@).is('.toggle_label')
    console.log $(@).html()
    $(@).html if $(@).html() == '↓' then '→' else '↓'

  if $(@).is('.toggle_group')
    $(@).parents('.group_outer').toggleClass 'closed'

  false



# broke all comment pagination.  Do we need multiple comment sets on any on page?  yes, in "read all" view...
# but still want to be able to have diff .page arguments....

# connect .pagination automatically to load_to
# allow .rel block to set a rel
'.pagination a'.live ->
  # console.log "comment paginate", @
  # return $(@).addClass('get').attr 'rel', '.backgrounds_page' if $(@).parents('.backgrounds_page').length
  $(@).addClass('get only')
  return if $(@).attr('rel') or $(@).parents('.rel').attr('rel') or !$('.page').length or $(@).parents('#msg-window').length or $(@).parents('.nopage').length
  $('.pinned_area').remove()
  # move comment-reply to body so it doesnt get removed
  $('body').append $('#comment-reply')
  $(@).attr 'rel', '.page'
  true

'.backgrounds_page .pagination a'.live (e) ->
  # e.preventDefault()
  $(@).text('...')
  num = $(@).attr('href').match(/\d+$/)[0]
  bg_area = $(@).parents('.backgrounds_page')
  console.log "num: #{num}, parent", bg_area

  $.get "/image/more#{if $(@).parents('.tab_site').length then '_site' else ''}?page=#{num}", (d)->
    console.log "parent now", bg_area
    bg_area.replaceWith d
  false

# window.dbg = 1
'a.get'.live (e) ->
  return if $(@).parents('.backgrounds_page').length # not skipping w/false
  t = $(@); r = $( t.attr 'rel').first()
  if !r.length then r = $(t).parents('.rel').first()
  # console.log "t is, a.get target is", t, r

  if !r.show().length
    console.log("#{t.attr 'rel'} not found - redirecting") if window.dbg
    # e.stopPropagation() # live binds to body so can't stop propogation
    # redirect_to t.attr('href') # no ajax if missing target.  return true = fail b/c toggle_tabs ran
    return true

  e.preventDefault()

  if t.hasClass('get') # pagination needs to overwrite existing... I guess thats ok always? and r.children().length < 2
    # console.log "loading #{t.attr('href')}?&ajax into", t
    r.html(wait_gif) #.load( $(@).attr 'href' )
    $.get _urladd(t.attr('href'), 'a2'), (d) ->
      r.html(d).show()
      after_ajax(r)
      r.data('p',1) # needed for tabs that start at 0
      window.timefix() # needed for tabs b/c they start out hidden I think? MutationObserver isn't seeing them

    # not entirely sure what the point of 'only' is anymore....
    # $(@).attr('data-no-turbolink', 1)
    # ajax uses pushstate, others use location hash - may need to add an exception if theres an ajax-only accessible tab
    #  and

    
    # this isn't working well to go back, it just does nothing when I pop it back off
    # if t.hasClass('only') and !t.attr('href').match(/image\/more/) and window.history #and !window.Turbolinks
    #   console.log "adding pushstate", window.Turbolinks
    #   # breaks back button entirely with turbolinks if we don't pushstate for the transition for some reason
    #   try
    #     # history.pushState {}, '', t.attr('href')
    #     history.pushState {turbolinks: {}}, "", t.attr('href')

  false

window._urladd = (url, param) ->
  url += "?" unless url.match /\?/
  url += "&#{param}"
  url


#.toggle_tabs parent has data(off), children have rel=.div, href, class+get if remote tabs

'.toggle_tabs a'.live ->
  t = $(@) #; r = $( t.attr 'rel')
  console.log "toggle-tab", @ , $(t.attr 'rel').length # , t, r, r.length
  if !$(t.attr 'rel').length 
    console.log "redirecting because rel #{t.attr 'rel'} missing"
    return true # does this break anything?
    #redirect_to(t.attr('href'), 'no_warning') # missing target tab # triggering AYS twice atm

  
  # clear search, for memphis
  # $('.results').hide() #html("<p class='my1 center'>Results</p>").hide()
  t.parent().find('.active').removeClass('active').end().end().addClass 'active' # move active class to us
  $( t.parent().data 'off' ).hide() # always the same list = store in parent
  unless t.hasClass('only')
    location.hash = t.attr('rel').replace(/^\./,'') 
    # update_turbo_referrer(window.location+location.hash) # update referrer shown? shouldn't b eneeded

  inf_scroll() # loads the poems in this tab
  footer_bottom() # why's this here twice
  $( t.attr 'rel' ).show() unless t.hasClass 'get' # what triggers the 'get'?
  # can't get this to work for ads `if (!window.paid) (adsbygoogle = window.adsbygoogle || []).push({});`
  footer_bottom()
  false #true

window.toggle_hash = ->
  return if $('body').hasClass('usershow') or $('body').hasClass('contestshow') # disable for this page for now, issue with infinite scroll

  $(".toggle_tabs a[rel=\".#{  location.hash.replace(/^#/,'')  }\"]").trigger 'click'

# COMMENTS
# autoresize = () ->
#   this.style.height=0;this.style.height = "#{this.scrollHeight + 2}px"

# want to get rid of this, it makes a strange shuddering effect
# arg.addEventListener( 'keyup', function ( event ) { this.style.height = `${this.scrollHeight + 2}px` } )
'textarea'.live 'click focus touchend', ->
  @.addEventListener 'input', -> this.style.height=0;this.style.height = "#{this.scrollHeight + 2}px"
  this.style.height=0;this.style.height = "#{this.scrollHeight + 2}px"

  unless @.classList.contains('no_auto_rich')
    $(@).removeClass('closed').addClass('open').trigger('keyup').next('input[type=submit]').show() # blur = activate autogrow
    $(@).parents('.body, .btn_body, form').removeClass('closed').addClass('open').find('input[type=submit]').show()
    auto_editor(@) if user and user.plan == 'gold' #and !$(@).parent().hasClass('area_wrap')

  # need to manually re-focus for safari. apple is such a shit company
  $(@).focus()
  true

#auto double spacing
'textarea'.live 'keydown', (e) ->
  return true if /iPad|iPhone|iPod/.test(navigator.userAgent) && !window.MSStream; # see if this is the cause of iphone problems
  # console.log "auto double spacing"
  return $(@).parents('form').submit() if e.keyCode == 13 and (e.metaKey || e.ctrlKey) # submit form if cmd+enter
  return true if e.keyCode != 13 or e.metaKey or e.altKey or e.ctrlKey or e.shiftKey or $(e.target).is("#item_notes, .new") or $(e.target).parents('.poem_body').length
  # console.log "continuing, e:", e, e.keyCode, e.metaKey, e.ctrlKey
  
  # console.log "checking: ",  $(@).val(), $(@).val().match(/\n$/)
  return true if $(@).val().match(/\n$/) # prevent if last char in the textarea is already a newline
  insertAtCaret(@, "\n")
  true

window.auto_id=0
window.auto_editor = (ele) ->
  e = $(ele)
  return if e.hasClass('no_auto_rich')
  return if $('body').is('.itemedit') and e.attr('id') == 'item_rendered' # exception
  # double-parent check cauess problems.  what was it necessary for?
  return if e.parent().find('.ed').length #or e.parent().is('#comment-quick') or e.parents('.no_auto_rich').length
  # console.log "adding editor buttons"
  e.attr('id', "tk_"+window.auto_id+=1) unless e.attr('id') # give unique id
  link = $('<a class="toggle-editor auto-editor" data-no-turbolink=1 tabindex="-1" href="#" data-id="'+ e.attr("id") + '"><i class="sprite ed"></i></a>')

  return true if ele.offsetWidth <= 100
  # console.log "wrapping", ele
  # css('width', ele.offsetWidth).
  # I think this is causing the propblem with firefox and focus()...
  # first, lets see the problem we were solving by wrapping it first
  # e.wrap($("<div class='area_wrap relative'></div>")).focus().parent().prepend link
  e.parent().prepend link

  # e.focus()
  # delay 15, -> e.focus() # curse you, firefox!
  false


# open a comment reply box when people click a comment.  was .comments > .media
'.comments .media > .media-body'.live (e) ->
  # console.log "cmt reply", @
  return if $(e.target).is('a, textarea') or $(@).next('form').length or $(@).hasClass('no_rep') or $(@).parents('.remote').length or $(@).children('#comment-reply').length
  # return if $(e.target).is('a,input,textarea') or $(e.target).parents('#top-comment').length or $(e.target).parents('.no_reply, .im').length
  $('#reply-parent-id').val $(@).parents('.media').data('id')
  $(@).append $('#comment-reply').show()

  # trouble with firefox triggering this, try wrapping in a timeout
  delay 10, -> $('#comment-reply textarea').focus() # needs to be on its own line for some reason


'.comment-form'.live 'submit', ->
  # console.log "comment submit", $(@).serialize()
  # error: (a,b,c) -> console.log "comment error", a, b, c
  $.ajax
    url: '/comment/add', data: $(@).serialize(), type: 'POST',
    success: (html) =>
      # console.log "got", html
      $(@).find('input[type=submit]').val("Comment added!").end().find('input.btn').hide()
      $(@).removeClass('open').addClass('closed').find('textarea').removeClass('open').addClass('closed').attr('placeholder', "Added comment").css('height','').css('min-height','0').blur().val('')
      # console.log "textarea is now", $(@).find('textarea')
      # $(@).find('textarea').addClass "blah" # waht was this for?

      # hide ckeditor if it's open
      tx = $(@).find('textarea')
      tx.ckeditorGet().destroy() if tx.is('[style*="display: none"]')  # zepto compat

      commented_record() if $.cookie('jt') == '1' # 'links' view

      $(@).find('.area_wrap').css('display', 'inline') # fix formatting for gold members

      # $('.comment-form').trigger 'reinitialize.areYouSure'
      par = $(@).parents('.media') # don't want added to base .sub eh?  looks wierd.  # ok to use 'form'?
      res = $(html).insertAfter( if par.length then par else @ ).addClass( 'slidedown')

      # $('.sub abbr').timeago()
      # .ou & latest date weren't running
      # console.log("newest")
      window.cmt = res
      # $('abbr', res).timeago()
      # window.timefix(par)
      $(".ou.u_#{user.id}", res).show()
      read_more() # long comments have read more
      cmt_right_side_fix res

  false

'.right_comment .comment-form textarea'.live 'keyup', (e) -> 
  if e.keyCode == 13 then cmt_right_side_fix_actual(e.target) else cmt_right_side_fix(e.target)

# careful about placement of this so it can get called before ajax methods (== at bottom?)
'form'.live 'submit', ->
  disableform( window.recent_form = $(@) )
  # 
  # setTimeout()

# what about if there are two form/link buttons within a form, how to tell which one was clicked?  have .btn onclick do it's own thing...
# this is only called for *ACTUAL* forms, which need *ACTUAL* buttons...
# disableFormElements = (ele) ->
#   e = $(ele)
#   found = 0
#   e.find('input[type=submit]').attr('disabled','disabled').each ->
#     $(@).val $(@).val()+ ' ...'
#     found=1
#
#   btn =e.find('a.btn').attr('disabled','disabled')
#   btn.first().append(wait_gif) if !found


# we add the .. with css on the :disabled condition for a.btn, but this is input btn
# yup, this results in form values not being passed.... aah b/c they are disabled. UGH!
# what's re-enabling them? I don't quite get it.  Maybe 
window.disableform = (ele) ->
  $(ele).find('input[type=submit]').attr('disabled','disabled')
  # .each ->
  #   $(@).val $(@).val()+ ' ...'

window.enableform = (ele) ->
  ele ||= $('body')
  $('.wait').hide()
  $(ele).find('input[type=submit]').attr('disabled',null).each ->
    $(@).val $(@).val().replace(' ...', '')

# remote post.  ajax setup adds csrf-token already.
'a.remote'.live ->
  console.log "a.remote", @
  if !$(@).data('confirm') or confirm($(@).data 'confirm')
    # console.log "continuing..."

    # console.log "a.remote", $(@).attr('href')
    $(@).after " <span class='wait'>...</span>"

    $.post $(@).attr('href'), (data) =>
      # console.log "data-remote got data: #{data}"
      $(@).css('margin-right', 0) if $(@).hasClass 'clickpad' # breaks the margin-right aspect of .clickpad
      $(@).after( " &nbsp; "+data+" &nbsp; ").parent().find('.wait').remove()
      $(@).addClass('active').html("<i class='fa fa-heart'></i> Liked") if $(@).is('.lk')
  false

# ajax setup adds csrf. $.extend({authenticity_token: $( 'meta[name="csrf-token"]' ).attr( 'content' )}
'form.remote'.live 'submit', ->
  # $(@).after "<span class='wait'>...</span>" # we're also adding this to the button itself already now...
  console.log "sending remote form" #, $.extend({authenticity_token: $( 'meta[name="csrf-token"]' ).attr( 'content' )}, $(@).serialize())

  $.post $(@).attr('action'), $(@).serialize(), (data) =>
    $('.wait').remove()
    $(@).after( " &nbsp; "+data+" &nbsp; ") if (typeof data) == "string"
    $(@).find('textarea').removeClass('open').addClass('closed').css('height',null).val('')
    $(@).parent().find('input.btn').hide() #.end().find('abbr').timeago()
    # window.timefix($(@).parent())
    
    $(@).attr("placeholder", "Sent")
    $(".ou.u_#{user.id}").show()
  false

# can't use form.remote for msgs since often inside other forms.  hard to get keypress to work like form submits
'.say_form .btn'.live 'keydown', (e) -> if e.keyCode == 32 then $(@).trigger 'click'; false

'.say_form .btn'.live 'click', ->
  p = $(@).parent()
  $.post '/messages/say', {'msg[body]': p.find("textarea").val(), 'msg[to]': p.data('to')}, (data) =>
    console.log(data)
    p.after( "<br>"+data+" &nbsp; ") if (typeof data) == "string"
    p.find('textarea').attr('placeholder', "Sent").val('').removeClass('open').addClass('closed').css('height',null)

# let space button send 'a.btn'
# 'window'.live 'keydown', (e) -> $(e).trigger('click') if e.keyCode == 32 && $(e.target).is('.btn')

# btn group enabling js - this doesn't show any checked=checked in the inspector but it DOES work
'.btn-group label.btn'.live ->
  $(@).parents('.btn-group').find('.active').removeClass('active').end().find('[checked=checked]').prop('checked',false)
  $(@).addClass('active').children().prop('checked', true)
  false

# which is it?
# ".btn-group input".live ->
#   $(@).parent().parent().find('input').attr('checked','').end().find('.active').removeClass 'active'
#   $(@).attr('checked','checked').parent().addClass 'active'   # check us and mark us active


# DROPDOWNS
# try disabling auto-close when we mouse out? We do want it for the groups nav menu though
window.delays = {}
".dropdown-toggle, .dropdown-menu".live "mouseover", ->
  $(".dropdown-menu").css "display", ""
  for de of delays
    clearTimeout delays[de]
  t = (if $(this).hasClass("dropdown-toggle") then $(this).next(".dropdown-menu") else $(this))
  t.css "display", "block"
  # $("img", t).css "display", "inline" # let us mark images hidden so they don't load until mouseover (online)

".dropdown-toggle, .dropdown-menu".live "mouseout", ->
  return if $(@).is('.top_user_menu')
  mt = $(this)
  clearTimeout delays[mt]  if delays[mt]
  delays[mt] = delay 320, ->
    t = (if mt.hasClass("dropdown-toggle") then mt.next(".dropdown-menu") else mt)
    t.css "display", ""

    
# tablets click dropdown to open it, rather than following the link
'.dropdown-toggle'.live ->
  # log "in click handler"
  return false if !!("ontouchstart" of window)
  true

'.popular_tags a'.live ->
  return if window.id == 'popular_more'

  t = $(@).parents('form').find('[id*=tag_list]')
  return false if t.val().split(',').length > 8 # if not too many tags already
  return false if t.val().match( $(@).text() )  # already exists

  if !t.val().match(/^$|,\s*$/) # add a comma
    t.val(t.val()+', ')

  t.val(  t.val() + $(@).text()  )
  # cat_image()
  false

'#popular_more'.live ->
  # $(@).hide()
  $('#popular_plus').toggle()
  false

# what's the goal of this?
'.itm'.live (e) ->
  # return # disable for a sec

  window.t = $(e.target)
  return if $(e.target).is('input, .x, i.fa-times, a') or $(e.target).parents('.sortable').length  # a == already transitioning with turbolinks?
  console.log 'visiting itm', e.target

  # will it ever be inside .media-bodya nymore?  dont think so .media-body a,
  lnk = $(@).find('.details a').not('.x').first()
  lnk = $(@).find('a.u').first() if !lnk.length and $('body').hasClass('favs')
  return window.open(lnk.attr 'href') if e.metaKey or e.altKey or e.ctrlKey
  return lnk.click() #if lnk.hasClass('remote') or $(e.target).closest('[data-turbolinks=false]').length # open like regular
  # console.log ".itm redirecting to #{lnk.attr 'href'}", e.target
  #redirect_to lnk.attr('href')
  # lnk.click()
  #false


# context menu for names

# ###########  CONTEXT MENU   #######

".u".live 'contextmenu', (e)->
  if $(@).parents('.online_favs_list').length
    $(@).trigger('click')
    return false
  # console.log "context", @
  e.preventDefault()
  $('#umenu').css('left', e.pageX).css('top', e.pageY).show()
  $('#profile').attr 'href', $(@).attr('href')
  window.umenu_obj = @

  delay 1, ->
    $(document).one 'click', ->
      $('#umenu').hide()

'#profile'.live ->
  $('#umenu').hide()
  window.open $(@).attr("href"), "_blank"
  false

'#message'.live ->
  $('#umenu').hide()
  m = $(window.umenu_obj)
  msg_window(m, m.data('name') || m.text())
  false


###################  THEMES  ########################
'#themes-color'.live 'click', ->
  unless window.jscolor
    sc = document.createElement("script")
    sc.src = '/javascripts/jscolor/jscolor.js'
    sc.async = true
    sc.addEventListener 'load', (e) ->
      console.log "loaded jscolor"
      jscolor.bind();
      $('input.color:first').get(0).color.showPicker() # show right away?  Hmm...

    document.head.appendChild sc

"#themes-color".live "change blur", ->
  v = $(this).val()
  console.log "color is #{v}, brightness: #{brightness(v)}"
  if v == '#FFFFFF' or v == ''
    return false

  $('#bgcolor').val( v )
  $('#bg').val ''
  $('body').removeClass('light_text').css('background','none').css('background-color', v)
  $('ul.themes li.active').removeClass 'active'
  $('body').removeClass('light_text')
  $('body').addClass('light_text') if brightness(v) < 382.6  #this is kinda inexact, there are a lot of #'s that have very poor contrast.  Could ignore if it's in the 300-400 range, but hard to 'adjust' the color for them + a bit confusing?  Not worrying about it too much atm.

window.brightness = (hex_color) ->
  c = hex_color.replace(/^#/,'')
  parseInt(c[0]+c[1], 16) + parseInt(c[2]+c[3], 16) + parseInt(c[4]+c[5], 16)


# IMAGES
'ul.themes > li > div'.live ->
  $('ul.themes .active').removeClass('active')
  $(@).parent().addClass 'active'
  $('#bg').val $(@).data('custom')
  $('.lighteness_min').text $(@).data('auto')
  $('#lighten_box').css 'display', if ($(@).data('mine') or user.a) then 'block' else 'none'
  $('#lighten').val($(@).data('lighten')).trigger('change') # trigger lighten
  $('.del_bg').hide()

  if $(@).data('mine') == 1
    $('#item_form').removeClass 'dirty' # avoid warning
    $('.del_bg').show().attr('href', "/image/del_bg/#{ $(@).data('custom') }")

'.del_bg'.live ->
  return if $(@).attr('href') == '#'

  if confirm $(@).data('confirm')
    $.post $(@).attr('href'), ->
      $('li.active').html "<div>deleted</div>"

  false

# Lightness changes.  Set the background + lighten, then trigger to set
'#lighten'.live 'change blur keyup', ->
  t = parseInt($('#lighten').val()) || 0
  console.log "lighten #{t}"
  abs_t = Math.abs t
  sf = "#{if t > 0 then '255,255,255' else '0,0,0'}, #{abs_t / 100}"

  # damn, not set if we're using a custom BG, since it's on the 'site' tab by default...
  # if nothing is selected, use the window's current background image!
  if $('ul.themes .active div').length
    bg = $('ul.themes .active div').first().css('background-image').replace('-thumb','')
  else
    bg = $('body').css('background-image').replace(/^.*url/,'url')

  $('body').removeClass 'light_text'
  $('body').addClass 'light_text' if t < 0

  if abs_t < 3
    $('body').css background: bg
  else
    console.log "setting with gradient: linear-gradient(to bottom, rgba(#{sf}) 0%, rgba(#{sf}) 100%), #{ bg }"
    $('body').css
      zoom: 1
      background: "linear-gradient(to bottom, rgba(#{sf}) 0%, rgba(#{sf}) 100%), #{ bg }"

'.hide_bg'.live ->
  $('body').css('background', '').removeClass('light_text').addClass 'simpler'
  $('.fonted').css('font-family','')
  false

# hmm, lightening the background is hard too... damn, forgot about that...

# '.ifr'.live ->
#   a = $(@)
#   return if a.is('.loaded')
#   $(@).html "<iframe allowfullscreen='allowfullscreen' src='https://www.youtube.com/embed/#{a.data 'c'}#{if a.data('c').match(/\?/) then '&' else '?'}autoplay=1&rel=0' height=#{a.height()} width=#{a.width()}></iframe>"
#   $(@).removeClass('ifr').addClass 'ifr_loaded'
#   false

# simpler version supporting both YT and soundcloud? not converting YT yet though. <div><img>
# sometimes a 'strong' will move inside via chrome and break this, move to queryString
'.ifra'.live (e) ->
  # console.log(e, e.target)
  ch=e.target.querySelector('img') #childNodes[0]
  return if !ch or !ch.dataset
  return console.log("Skipping ifra, didn't match youtube|soundcloud", ch.dataset.src) if !ch.dataset.src.match(/^https:\/\/www.youtube|^https:\/\/(w\.)?soundcloud.com/)
  @.classList.remove('ifra')
  @.outerHTML = ch.outerHTML.replace(/<img .*?data\-/,'<iframe ').replace(/<\/img>/,'</iframe>')
  false


# todo: new cross-browser version
window.insertAtCaret = (txtarea, text) ->
  scrollPos = txtarea.scrollTop
  strPos = 0
  br = ((if (txtarea.selectionStart or txtarea.selectionStart is "0") then "ff" else ((if document.selection then "ie" else false))))
  if br is "ie"
    txtarea.focus()
    range = document.selection.createRange()
    range.moveStart "character", -txtarea.value.length
    strPos = range.text.length
  else strPos = txtarea.selectionStart  if br is "ff"
  front = (txtarea.value).substring(0, strPos)
  back = (txtarea.value).substring(strPos, txtarea.value.length)
  txtarea.value = front + text + back
  strPos = strPos + text.length
  if br is "ie"
    txtarea.focus()
    range = document.selection.createRange()
    range.moveStart "character", -txtarea.value.length
    range.moveStart "character", strPos
    range.moveEnd "character", 0
    range.select()
  else if br is "ff"
    txtarea.selectionStart = strPos
    txtarea.selectionEnd = strPos
    txtarea.focus()
  txtarea.scrollTop = scrollPos

'#item_paper'.live ->
  # $('body').addClass('paper-white').removeClass 'paper-black'
  $('body').addClass('paper-black').removeClass 'paper-white'

  if $('#item_paper').prop('checked')
    # $('body').addClass('paper-black').removeClass 'paper-white'
    $('body').addClass('paper-white').removeClass 'paper-black'

# toggle on/off again, hmm.
'.bg_toggle'.live ->
  if $(@).is(':checked')
    console.log("adding theme", $(@).data 'st')
    $('body').css('background-image','none').addClass('light_text') if $(@).hasClass 'light_text'
    $('body').attr('style', $(@).data('st'))   # howt o set style directly via css?
    $.cookie("b_#{ $(@).data 'uid' }", null) # remove cookie
    $('.header-pen, .raven').hide()

  else
    console.log 'unchecked'
    $('body').css('background', '').removeClass('light_text') #.addClass 'simpler'
    $.cookie("b_#{ $(@).data 'uid' }", 1, 999)
    # $('.fonted').css('font-family','')

  true # allow it to be checked

'.ratings a'.live ->
  $(@).parent().find('.sel').removeClass('sel')
  $(@).addClass('sel')
  false

'#chatter'.live 'submit', ->
  # chat_set_timeout()
  $.post '/chat/say', $(@).serialize(), ->
    # if (client.getState() != "CONNECTED") # refresh-on-submit fallback
    if !App.room #App.cable or App.cable.connection.disconnected
      # chat_history()
      $('#chat-here').append "<div>"+client.getState().toLowerCase()+"</div>"

    $('#chatter input[name=body]').val('').focus()
  false

# also in comment-form#submit
# is this still necessary since we now put comments in the left side?
# '.right_comment .comment-form textarea'.live 'keyup', (e) -> cmt_right_side_fix e.target
# fixing bug when you zoom the page
window.cmt_right_side_fix = (e) ->
  return if window.cmt_fix_timer
  # console.log('still setting', window.cmt_fix_timer)
  window.cmt_fix_timer = delay 400, -> cmt_right_side_fix_actual(e)

# 'e' is the textarea.  sometimes giving 'null' to closest .cmt_wrap.  Maybe there's 
# some design differences between forums & poems here it seems, would love to simplify this
window.cmt_right_side_fix_actual = (e) ->
  window.cfix = e
  # console.log( e.closest('.cmt_wrap'), e.closest('form'))
  try
    if !e.closest('.cmt_wrap') or !e.closest('form')
      console.log("Skipping cmt_right_side, no .cmt_wrap/form")
      return
    
    # console.log("closest", e.closest('form'))

    # zepto irritatingly wraps this and doesn't forward .querySelector
    # v=e.closest('.cmt_wrap').offsetHeight+$(e.closest('form')).find('.bio').offsetHeight
    v=e.closest('.cmt_wrap').offsetHeight+e.closest('form').querySelector('.bio').offsetHeight
    p=e.closest('.comment-form')
    # console.log(v, p.offsetHeight)

    p.style.minHeight = v+'px'
    window.cmt_fix_timer = null
  catch
    console.log "skipping cmt_right_side_fix_actual, caught", e
  

'.submit_all'.live ->
  $('.comment-form').each (idx,e) ->
    $(e).trigger('submit') if $(e).find('textarea').val().length > 0
  false

'.mob_details'.live ->
  return $('.mob_detail').toggle() if $('.mob_detail div').length > 1
    
  $('.show_lists, .show_contests').show()
  $('.mob_detail').append( $('.list_info').removeClass('hidden-xs hidden-sm')).show()
  false

'.hide_contests'.live ->
  $('li.contest').toggle()